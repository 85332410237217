/* eslint-disable no-useless-escape */
import { isEmpty, get } from 'lodash';
import request from '../utils/axiosRequest';
import axios from 'axios';
import toast from '../utils/toast';
import { getDNS } from '../utils/helpers';

export const currentUser = () => {
    let user = sessionStorage.getItem('user');
    if (user) {
        user = JSON.parse(user);
        return {
            ...user,
            superadmin: user['custom:role'] === 'superAdmin'
        };
    } else {
        return {
            superadmin: false
        };
    }
};

export const BASE_URL = () => {
    // run local for local debugging of API
    if (process.env.REACT_APP_APIMODE === 'local') return `http://localhost:3000/api/v5`;

    return `https://${getDNS()}.comcierge.hatchmed.com/api/v5`;
};

export const SUPER_BASE_URL = () => {
    if (process.env.REACT_APP_DEV === 'master') return `https://master.connectors.hatchmed.com/api/v4`;
    if (process.env.REACT_APP_DEV === 'staging') return `https://staging.connectors.hatchmed.com/api/v4`;
    return `https://production.connectors.hatchmed.com/api/v4`;
};


const login = async (email, password, superAdmin = false) => {
    try {
        let res;
        if (superAdmin == true) {
            // console.log(`apiLoginSUPER: ${email}  ${type}  ${currentUser().superadmin} <> ${superAdmin}`);
            res = await axios.post(`${SUPER_BASE_URL()}/auth/login?superadmin=true`, { name: email, password: password });
        } else {
            // console.log(`apiLogin: ${email}  ${type}  ${currentUser().superadmin} <> ${superAdmin}`);
            res = await request.post(`${BASE_URL()}/auth/login?superadmin=false`, { name: email, password: password });
        }
        return res.data;    
    } catch (error) {
        toast.error(error);
    }
};

const loginByod = async (linkId) => {
    const res = await request.post(`${BASE_URL()}/byod-links/login/${linkId}`);
    return res.data;
};

const getByodLinks = async () => {
    const res = await request.get(`${BASE_URL()}/byod-links`);
    return res.data;
};

const generateByod = async (bayId) => {
    const res = await request.post(`${BASE_URL()}/byod-links`, { bayId: bayId });
    return res.data;
};

const deleteByodLink = async (linkId) => {
    const res = await request.del(`${BASE_URL()}/byod-links/${linkId}`);
    return res.data;
};

const connectorOrganizations = async (token) => {
    let res = await axios.get(`${SUPER_BASE_URL()}/connector-organisations`, {
        headers: {
            Authorization: token
        }
    });
    if (res) {
        return res.data;
    } else {
        toast.error('Failed to connectorOrganizations');
        return res.success;
    }
};

const getOrganization = async (org_id) => {
    let res = await request.get(`${BASE_URL()}/organisations/${org_id}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get room');
        return res.success;
    }
};

const getOrganizations = async () => {
    let res = await request.get(`${BASE_URL()}/organisations?`);
    if (res.success) {
        return res.data;
    } else {
        return res.success;
    }
};

const getConnectorOrganizations = async (data) => {
    let res = await request.get(`${BASE_URL()}/connector-organisations`);
    if (res.success) {
        return res.data;
    } else {
        return res.success;
    }
};

const getConnectorOrganization = async (id) => {
    let res = await request.get(`${BASE_URL()}/connector-organisations/${id}`);
    if (res.success) {
        return res.data;
    } else {
        return res.success;
    }
};

const deleteConnectorOrganizations = async (id) => {
    let res = await request.del(`${BASE_URL()}/connector-organisations/${id}`);
    if (res.success) {
        return res.data;
    } else {
        return res.success;
    }
};

const createConnectorOrganizations = async (data) => {
    let res = await request.post(`${BASE_URL()}/connector-organisations`, data);
    if (res.success) {
        return res.data;
    } else {
        return res.success;
    }
};

const getUser = async (type = 'admin', uid) => {
    let res;

    if (type === 'superAdmin') {
        res = await request.get(`${BASE_URL()}/admins/${uid}?superadmin=true`);
    } else if (type === 'admin') {
        res = await request.get(`${BASE_URL()}/admins/${uid}?`);
    } else if (type === 'nurse') {
        res = await request.get(`${BASE_URL()}/nurses/${uid}`);
    } else {
        return { success: true };
    }

    if (res.success) return res.data;
    if (res.data === 'Network Error') return null;
    else return 'auth/user-not-found';
};

const generateReport = async (orgId) => {
    let res = await request.get(`${BASE_URL()}/report/${orgId}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to generate report');
        return res.success;
    }
};

const getNurses = async () => {
    let res = await request.get(`${BASE_URL()}/nurses`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get nurse list');
        return res.success;
    }
};

const getNurse = async (nurseId) => {
    let res = await request.get(`${BASE_URL()}/nurses/${nurseId}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get nurse info');
        return res.success;
    }
};

const createNurse = async (nurseData) => {
    let res = await request.post(`${BASE_URL()}/nurses?`, nurseData);
    toast.success('Nurse successfully created');
    return res.data;
};

const updateNurse = async (nurseId, nurseData) => {
    let res = await request.patch(
        `${BASE_URL()}/nurses/${nurseId}/credentials`,
        nurseData
    );
    if (res.success) {
        toast.success('Nurse successfully updated');
        return res.data;
    } else {
        throw res.data;
    }
};

const deleteNurse = async (nurseId) => {
    let res = await request.del(`${BASE_URL()}/nurses/${nurseId}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete nurse');
        return res.success;
    }
};

const getUnits = async () => {
    let res = await request.get(`${BASE_URL()}/units`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get unit list');
        return res.success;
    }
};

const getUnit = async (unitId) => {
    let res = await request.get(`${BASE_URL()}/units/${unitId}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get unit info');
        return res.success;
    }
};

const createUnit = async (nurseData) => {
    let res = await request.post(`${BASE_URL()}/units`, nurseData);
    toast.success('Unit successfully created');
    return res.data;
};

const updateUnit = async (unitId, nurseData) => {
    let res = await request.patch(`${BASE_URL()}/units/${unitId}`, nurseData);
    if (res.success) {
        toast.success('Unit successfully updated');
        return res.data;
    } else {
        throw res.data;
    }
};

const deleteUnit = async (unitId) => {
    let res = await request.del(`${BASE_URL()}/units/${unitId}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete unit');
        return res.success;
    }
};

const getAssignment = async () => {
    let res = await request.get(`${BASE_URL()}/assignment?`, true);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get assignment');
        return res.success;
    }
};

const deleteBayForNurse = async (nurseUid, bay_id) => {
    let res = await request.post(`${BASE_URL()}/nurses/${nurseUid}/unassign?`, {
        bay_id
    });
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete bay for nurse');
        return res.success;
    }
};

const addBaysForNurse = async (nurseUid, bay_id) => {
    let body = {
        bay_id,
        primary: false
    };
    let res = await request.post(
        `${BASE_URL()}/nurses/${nurseUid}/assign?`,
        body
    );
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to add bays to nurse');
        return res.success;
    }
};

// this returns the smarthubActions table
const getShActions = async () => {
    let res = await request.get(`${BASE_URL()}/smarthub-actions`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to retrieve smarthub_actions');
        return res.success;
    }
};

// this returns the action-apis table
const getShActionsApis = async () => {
    let res = await request.get(`${BASE_URL()}/action-apis`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to retrieve actions');
        return res.success;
    }
};

// TODO cna 20240214 - how do we send for EAP_TLS ???
const updateSmarthubCredentials = async (mac, ssid, password) => {
    let res = await request.post(`${BASE_URL()}/smarthubs/configure/wifi/${mac}`, `{\"ssid\":\"${ssid}\",\"password\":\"${password}\",\"connection_type\":\"STANDARD_WIFI\"}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to configure smarthub wifi');
        return res.success;
    }
};



const getBays = async (query = '') => {
    let res = await request.get(`${BASE_URL()}/bays?${query}&`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to retrieve bays');
        return res.success;
    }
};

const createBay = async (bayForm) => {
    let res = await request.post(`${BASE_URL()}/bays?`, bayForm);
    if (res.success) {
        return res.data;
    } else {
        console.error(res.data);
        toast.error('Failed to create a new bay');
        return res.success;
    }
};

const updateBay = async (bayId, updates) => {
    const smarthub = get(updates, 'smarthub');
    const smarthubBody = { smarthub };
    const bodyWithoutSmarthub = updates;
    delete bodyWithoutSmarthub['smarthub'];
    let res = await request.patch(
        `${BASE_URL()}/bays/${bayId}?`,
        bodyWithoutSmarthub
    );
    if (smarthub !== null && smarthub !== undefined) {
        res = await request.patch(
            `${BASE_URL()}/bays/${bayId}/smarthub`,
            smarthubBody
        );
    }
    if (res.success) {
        return res.data;
    } else {
        console.error(res.data);
        toast.error('Failed to update bay');
        return res.success;
    }
};

const deleteBay = async (bayId) => {
    let res = await request.del(`${BASE_URL()}/bays/${bayId}?`);
    if (res.success) {
        return res.data;
    } else {
        console.error(res.data);
        toast.error('Failed to delete bay');
        return res.success;
    }
};
/**
 *
 * Admin APIS
 */
const updateAdmin = async (uid, updates, superAdmin = false) => {
    let res;
    if (superAdmin == true) {
        res = await request.patch(`${BASE_URL()}/admins/${uid}/credentials?superadmin=true`, updates, true);
    } else {
        res = await request.patch(`${BASE_URL()}/admins/${uid}/credentials`, updates, true);
    }
    if (res.success) {
        toast.success('Settings successfully updated');
        return res.data;
    } else {
        toast.error('Failed to update admin settings');
        return res.success;
    }
};

const getAdmins = async (organisation) => {
    let res = await request.get(`${BASE_URL()}/admins?organisation=${organisation}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get admin list');
        return res.success;
    }
};

const getAdmin = async (adminId) => {
    let res = await request.get(`${BASE_URL()}/admins/${adminId}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get admin info');
        return res.success;
    }
};

const createAdmin = async (nurseData) => {
    let res = await request.post(`${BASE_URL()}/admins`, nurseData, true);
    toast.success('Admin successfully created');
    return res.data;
};

const deleteAdmin = async (adminId) => {
    let res = await request.del(`${BASE_URL()}/admins/${adminId}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete admin');
        return res.success;
    }
};

/**
 *
 * Partner APIS
 */
const getPartners = async () => {
    let res = await request.get(`${BASE_URL()}/partners`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get partner list');
        return res.success;
    }
};

const getPartner = async (partnerId) => {
    let res = await request.get(`${BASE_URL()}/partners/${partnerId}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get partner info');
        return res.success;
    }
};

const createPartner = async (nurseData) => {
    let res = await request.post(`${BASE_URL()}/partners`, nurseData);
    return res.data;
};
const updatePartner = async (uid, updates) => {
    let res = await request.patch(`${BASE_URL()}/partners/${uid}`, updates);
    if (res.success) {
        toast.success('Partner successfully updated');
        return res.data;
    } else {
        toast.error('Failed to update partner settings');
        return res.success;
    }
};
const deletePartner = async (adminId) => {
    let res = await request.del(`${BASE_URL()}/partners/${adminId}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete partner');
        return res.success;
    }
};

const updateOrg = async (orgId, updates, showToast = true) => {
    let res = await request.patch(`${BASE_URL()}/organisations/${orgId}`, updates);
    if (res.success) {
        if (showToast) toast.success('Organization settings successfully updated');
        return res.data;
    }
};

const updateOrgCredentials = async (orgId, updates) => {
    let res = await request.patch(
        `${BASE_URL()}/organisations/${orgId}/credentials?`,
        updates
    );
    if (res.success) {
        toast.success('Credentials successfully updated');
        return res.data;
    } else {
        toast.error('Failed to update organization settings');
        return res.success;
    }
};

const getAllAlerts = async () => {
    let res = await request.get(`${BASE_URL()}/alerts?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get all org alerts');
        return res.success;
    }
};

const getAlert = async (alert_id) => {
    let res = await request.get(`${BASE_URL()}/alerts/${alert_id}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get alert info');
        return res.success;
    }
};

const createAlert = async (data) => {
    let res = await request.post(`${BASE_URL()}/alerts?`, data);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to create alert');
        return res.success;
    }
};

const deleteAlert = async (alert_id) => {
    let res = await request.del(`${BASE_URL()}/alerts/${alert_id}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete alert');
        return res.success;
    }
};

const updateAlert = async (alert_id, data) => {
    let res = await request.patch(`${BASE_URL()}/alerts/${alert_id}?`, data);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to update alert');
        return res.success;
    }
};

const addAlertForBay = async (bay_id, alert_id) => {
    let res = await request.post(
        `${BASE_URL()}/bays/${bay_id}/alerts/${alert_id}?`
    );
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to add alert for bay');
        return res.success;
    }
};

const deleteAlertForBay = async (bay_id, alert_id) => {
    let res = await request.del(
        `${BASE_URL()}/bays/${bay_id}/alerts/${alert_id}?`
    );
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete alert for bay');
        return res.success;
    }
};

const getBay = async (bay_id) => {
    let res = await request.get(`${BASE_URL()}/bays/${bay_id}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get bay');
        return res.success;
    }
};

const unpairPatientFromBay = async (bay, type) => {
    let headers = {};
    if (type === 'device') {
        headers['x-device'] = bay.device_id;
    } else if (type === 'view') {
        headers['x-view'] = bay.view_id;
    }
    let res = await request.post(
        `${BASE_URL()}/bays/${bay._id}/unassign?`,
        null,
        headers
    );
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to unassign Patient device');
        return res.success;
    }
};

const unpairHallMonitor = async (room) => {
    let headers = {
        'x-hallmonitor': room.hallmonitor_id
    };

    let res = await request.post(
        `${BASE_URL()}/rooms/${room._id}/unassign?`,
        null,
        headers
    );
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to unassign HallMonitor device');
        return res.success;
    }
};

const getRooms = async (includeAlerts = false) => {
    let res = await request.get(`${BASE_URL()}/rooms?alerts=${includeAlerts}&`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get rooms');
        return res.success;
    }
};

const getRoom = async (room_id) => {
    let res = await request.get(`${BASE_URL()}/rooms/${room_id}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get room');
        return res.success;
    }
};

const getAggregatedStats = async (startTime = null) => {
    let res = await request.get(
        `${BASE_URL()}/reports/requests/aggregate?from=${startTime}&`
    );
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get aggregated data');
        return res.success;
    }
};

const generateReportTotals = async (startTime = null, groupBy = 'avg') => {
    let res = await request.get(
        `${BASE_URL()}/reports/requests/totals?groupBy=${groupBy}&from=${startTime}&`
    );
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get analytics data');
        return res.success;
    }
};

const getRequestReport = async (requestType, startTime = null) => {
    let res = await request.get(`${BASE_URL()}/reports/requests/${requestType}?from=${startTime}&groupBy=avg&`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get analytics for request');
        return res.success;
    }
};

const updateRoom = async (roomId, updates) => {
    let res = await request.patch(`${BASE_URL()}/rooms/${roomId}?`, updates);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to update room');
        return res.success;
    }
};

const addRoom = async (roomForm) => {
    let res = await request.post(`${BASE_URL()}/rooms?`, roomForm);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to add room');
        return res.success;
    }
};

const deleteRoom = async (roomId) => {
    let res = await request.del(`${BASE_URL()}/rooms/${roomId}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete room');
        return res.success;
    }
};

// isolation level

const getIsolations = async () => {
    let res = await request.get(`${BASE_URL()}/isolation-levels?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Isolations list');
        return res.success;
    }
};

const getIsolation = async (isolationId) => {
    let res = await request.get(`${BASE_URL()}/isolation-levels/${isolationId}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Isolations info');
        return res.success;
    }
};

const createIsolation = async (isolationData) => {
    let res = await request.post(
        `${BASE_URL()}/isolation-levels?`,
        isolationData
    );
    if (res.success) {
        toast.success('Isolation successfully created');
        return res.data;
    } else {
        toast.error('Failed to create isolations');
        return res.success;
    }
};

const updateIsolation = async (isolationId, isolationData) => {
    let res = await request.patch(
        `${BASE_URL()}/isolation-levels/${isolationId}?`,
        isolationData
    );
    if (res.success) {
        toast.success('Isolation successfully updated');
        return res.data;
    } else {
        toast.error('Failed to update isolations');
        return res.success;
    }
};

const updateIsolationOrder = async (idOrderPairs) => {
    let res = await request.patch(`${BASE_URL()}/isolation-levels?`, {
        idOrderPairs
    });
    if (res.success) {
        toast.success('Isolations order successfully updated');
        return res.data;
    } else {
        toast.error('Failed to update isolations order');
        return res.success;
    }
};

const uploadImage = async (orgId, file) => {
    let res = await request.post(`${BASE_URL()}/file-upload/upload/${orgId}`, file, { 'Content-Type': 'multipart/form-data' });
    if (res.success) {
        toast.success('Icon successfully uploaded');
        return res.data;
    } else {
        toast.error('Failed to upload icon');
        return res.success;
    }
}

const uploadSubIcons = async (orgId, files) => {
    let res = await request.post(`${BASE_URL()}/file-upload/subicons/${orgId}`, files, { 'Content-Type': 'multipart/form-data' });
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to upload Subicons');
        return res.success;
    }
}

const updateAlertOrder = async (idOrderPairs) => {
    let res = await request.patch(`${BASE_URL()}/alerts?`, { idOrderPairs });
    if (res.success) {
        toast.success('Alerts order successfully updated');
        return res.data;
    } else {
        toast.error('Failed to update Alerts order');
        return res.success;
    }
};

const deleteIsolation = async (isolationId) => {
    let res = await request.del(`${BASE_URL()}/isolation-levels/${isolationId}?`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to delete isolations');
        return res.success;
    }
};

const dischargeBay = async (bayId) => {
    let res = await request.post(
        `${BASE_URL()}/bays/${bayId}/discharge?`,
        {},
        {
            'x-device': bayId
        }
    );
    if (res.success) {
        toast.success('Discharged successfully');
        return res.data;
    } else {
        toast.error('Failed to discharge bay');
        return res.success;
    }
};

const unassignBay = async (bayId) => {
    let res = await request.post(
        `${BASE_URL()}/bays/${bayId}/unassign?`,
        {},
        {
            'x-device': bayId
        }
    );
    if (res.success) {
        toast.success('Unassign successfully');
        return res.data;
    } else {
        toast.error('Failed to Unassign bay');
        return res.success;
    }
};

const unassignRoom = async (roomId) => {
    let res = await request.post(
        `${BASE_URL()}/rooms/${roomId}/unassign?`,
        {},
        {
            'x-device': roomId
        }
    );
    if (res.success) {
        toast.success('Unassign successfully');
        return res.data;
    } else {
        toast.error('Failed to Unassign bay');
        return res.success;
    }
};

const getPatientRequests = async (query) => {
    let res = await request.get(`${BASE_URL()}/patient-requests?${query}&`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get patient requests list');
        return res.success;
    }
};

const getBayMe = async (xDevice, query = '') => {
    let res = await request.get(`${BASE_URL()}/bays/me?${query}&`, {
        headers: {
            'x-device': xDevice
        }
    });
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to retrieve bays');
        return res.success;
    }
};

// TODO: cna 20230125 WIP to xBYOD
const getBayMeByod = async (xDevice, query = '') => {
    let res = await request.get(`${BASE_URL()}/bays/me?${query}&`, {
        headers: {
            'x-byod': xDevice
        }
    });
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to retrieve bays');
        return res.success;
    }
};

const patientRequestAction = async (mode, requestId, data, headers) => {

    let res = await request.post(`${BASE_URL()}/patient-requests/${requestId}/${mode}?`, data, headers);
    if (res.success) {
        // toast.success('Updated Successfully');
        return res.data;
    } else {
        toast.error('Failed to Update Request');
        return res.success;
    }
};
const createPatientRequests = async (requestType, byod_id) => {
    if (isEmpty(byod_id)) {
        toast.error('Bay does not assign the device');
        return;
    }
    // console.log(`PatReq: ${device_id}  ${requestType}`);
    let res = await request.post(
        `${BASE_URL()}/patient-requests`,
        {
            request: requestType,
            sound: 'open'
        },
        {
            'x-byod': byod_id
        }
    );
    if (res.success) {
        // toast.success('Requested successfully');
        return res.data;
    } else {
        toast.error('Failed to create patient request');
        return res.success;
    }
};

const smartHubAction = async (data, byod_id) => {
    let res = await request.post(`${BASE_URL()}/smarthubs`, data, {
        'x-byod': byod_id
    });
    return res;
};

const siemensSetValue = async (data, xDevice) => {
    let res = await request.post(`${BASE_URL()}/siemens/setvalue`, data, {
        'x-device': xDevice
    });
    return res;
};



const createCallRoom = async () => {
    let res = await request.post(
        `${BASE_URL()}/calling/create`,
        {},
        {
            'x-device': 1
        }
    );
    if (res.success) {
        toast.success('Created Successfully');
        return res.data;
    } else {
        toast.error('Failed to create Request');
        return res.success;
    }
};

const joinCallRoom = async (roomId) => {
    let res = await request.post(
        `${BASE_URL()}/calling/join/${roomId}`,
        {},
        {
            'x-device': 1
        }
    );
    if (res.success) {
        toast.success('Joined Successfully');
        return res.data;
    } else {
        toast.error('Failed to join Request');
        return res.success;
    }
};

const getNurseAvatar = async (uid) => {
    let res = await request.get(`${BASE_URL()}/nurses/${uid}/avatar?`, {
        responseType: 'blob'
    });
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Nurse Avatar');
        return res.success;
    }
};

const getPatient = async (patient_id) => {
    let res = await request.get(`${BASE_URL()}/patients/${patient_id}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Patient info');
        return res.success;
    }
};

const getPatients = async () => {
    let res = await request.get(`${BASE_URL()}/patients`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Patients info');
        return res.success;
    }
};

const getShMacros = async () => {
    let res = await request.get(`${BASE_URL()}/shMacros`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Patients info');
        return res.success;
    }
}

const getShMacro = async (id) => {
    let res = await request.get(`${BASE_URL()}/shMacros/${id}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Patients info');
        return res.success;
    }
}

const updateMacro = async (id, data) => {
    let res = await request.patch(
        `${BASE_URL()}/shMacros/${id}?`,
        data
    );
    if (res.success) {
        toast.success('Macro successfully updated');
        return res.data;
    } else {
        throw res.data;
    }
}

const createMacro = async (data) => {
    let res = await request.post(
        `${BASE_URL()}/shMacros?`,
        data
    );
    if (res.success) {
        toast.success('Macro successfully updated');
        return res.data;
    } else {
        throw res.data;
    }
}

const deleteMacro = async (macroId) => {
    let res = await request.del(
        `${BASE_URL()}/shMacros/${macroId}`
    );
    if (res.success) {
        toast.success('Macro successfully deleted');
        return res.data;
    } else {
        throw res.data;
    }
}

const updateMacrosOrder = async (idOrderPairs) => {
    let res = await request.patch(`${BASE_URL()}/shMacros?`, {
        idOrderPairs
    });
    if (res.success) {
        toast.success('Macros order successfully updated');
        return res.data;
    } else {
        toast.error('Failed to update macros order');
        return res.success;
    }
};


// cna 20230901 get images (isolation levels/alerts/org logo) from API
// url exampled: https://organisation-files.s3.amazonaws.com/625889e217f5965271fc51f3/isolationLevelIcons/cb9a3581-b165-46f8-93ab-5490708c6878.png
const getImage = async (imageUrl) => {
    try {
        imageUrl = imageUrl.replace('https://', '').replace(/\//g, '~');
        let res = await request.get(`${BASE_URL()}/organisations/resources/${imageUrl}`, {
            responseType: 'blob'
        });
        if (res.success) {
            return res.data;
        } else {
            toast.error('Failed to get image');
            return res.success;
        }
    }
    catch (error) {
        console.error("Error getting image:", error);
    }
};

const enrollPatient = async (xByod, bay_id) => {
    let res = await request.post(`${BASE_URL()}/bays/${bay_id}/enroll_patient`, {}, {
        'x-byod': xByod
    });
    if (res.success) {
        return res.success;
    } else {
        toast.error('Failed to get enroll patient');
        return res.success;
    }
};

const getRequests = async () => {
    let res = await request.get(`${BASE_URL()}/requests`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Requests info');
        return res.success;
    }
};

const getRequest = async (id) => {
    let res = await request.get(`${BASE_URL()}/requests/${id}`);
    if (res.success) {
        return res.data;
    } else {
        toast.error('Failed to get Request info');
        return res.success;
    }
};

const updateRequest = async (id, data) => {
    let res = await request.patch(`${BASE_URL()}/requests/${id}?`, data);
    if (res.success) {
        toast.success('Request successfully updated');
        return res.data;
    } else {
        throw res.data;
    }
};

const createRequest = async (data) => {
    let res = await request.post(`${BASE_URL()}/requests?`, data);
    if (res.success) {
        toast.success('Request successfully updated');
        return res.data;
    } else {
        throw res.data;
    }
};

const deleteRequest = async (reqId) => {
    let res = await request.del(`${BASE_URL()}/requests/${reqId}`);
    if (res.success) {
        toast.success('Request successfully deleted');
        return res.data;
    } else {
        throw res.data;
    }
};

const respondOTPMFA = async (password, session, username) => {
    let res = await request.post(`${BASE_URL()}/auth/respond-otp`, { password: password, session: session, username: username});
    if (res.success) {
        toast.success('User verified');
        return res.data;
    } else {
        throw res.data;
    }
};

const respondSMSMFA = async (code, session, username) => {
    let res = await request.post(`${BASE_URL()}/auth/respond-sms`, { code: code, session: session, username: username});
    if (res.success) {
        toast.success('User verified');
        return res.data;
    } else {
        throw res.data;
    }
};

const respondSelect = async (method, session, username) => {
    let res = await request.post(`${BASE_URL()}/auth/respond-select`, { method: method, session: session, username: username});
    if (res.success) {
        return res.data;
    } else {
        throw res.data;
    }
};

const respondSetup = async (password, session, username) => {
    let res = await request.post(`${BASE_URL()}/auth/respond-setup`, { password: password, session: session, username: username});
    if (res.success) {
        return res.data;
    } else {
        throw res.data;
    }
};

const api = {
    login,
    loginByod,
    getByodLinks,
    generateByod,
    deleteByodLink,
    getShMacros,
    getShMacro,
    updateMacro,
    createMacro,
    deleteMacro,
    updateMacrosOrder,
    getPatient,
    getPatients,
    getNurseAvatar,
    connectorOrganizations,
    createCallRoom,
    joinCallRoom,
    getConnectorOrganizations,
    getConnectorOrganization,
    createConnectorOrganizations,
    deleteConnectorOrganizations,
    getOrganization,
    getOrganizations,
    getUser,
    generateReport,
    getNurses,
    getNurse,
    createNurse,
    updateNurse,
    deleteNurse,
    getAssignment,
    deleteBayForNurse,
    addBaysForNurse,
    getBays,
    getBayMe,
    getBayMeByod,
    createBay,
    updateBay,
    deleteBay,
    updateAdmin,
    createAdmin,
    deleteAdmin,
    getAdmins,
    getAdmin,
    updatePartner,
    createPartner,
    deletePartner,
    getPartners,
    getPartner,
    updateOrg,
    updateOrgCredentials,
    addAlertForBay,
    deleteAlertForBay,
    getBay,
    unpairPatientFromBay,
    unpairHallMonitor,
    getRooms,
    getRoom,
    getAggregatedStats,
    generateReportTotals,
    getRequestReport,
    updateRoom,
    addRoom,
    deleteRoom,
    getIsolations,
    getIsolation,
    createIsolation,
    updateIsolation,
    deleteIsolation,
    getAllAlerts,
    getAlert,
    createAlert,
    deleteAlert,
    updateAlert,
    updateAlertOrder,
    dischargeBay,
    unassignBay,
    unassignRoom,
    getPatientRequests,
    patientRequestAction,
    createPatientRequests,
    smartHubAction,
    updateIsolationOrder,
    getUnits,
    getUnit,
    createUnit,
    updateUnit, 
    deleteUnit,
    getShActions,
    getShActionsApis,
    updateSmarthubCredentials,
    siemensSetValue,
    getImage,
    enrollPatient,
    getRequests,
    getRequest,
    createRequest,
    updateRequest,
    deleteRequest,
    uploadImage,
    uploadSubIcons,
    respondOTPMFA,
    respondSMSMFA,
    respondSelect,
    respondSetup,
};

export default api;
