import * as mixpanel from 'mixpanel-browser';
import React from 'react';

export const MixpanelContext = React.createContext();

export const MixpanelProvider = ({ children }) => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL);

  const identifyUser = (id, details) => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_RUN_MIXPANEL
    ) {
      mixpanel.identify(id);
      mixpanel.people.set(details);
    }
  };

  const updateDetails = (details) => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_RUN_MIXPANEL
    ) {
      mixpanel.people.set(details);
    }
  };

  const resetUser = () => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_RUN_MIXPANEL
    ) {
      mixpanel.reset();
    }
  };

  const track = (event, props = {}) => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_RUN_MIXPANEL
    ) {
      mixpanel.track(event, props);
    }
  };

  return (
    <MixpanelContext.Provider
      value={{ identifyUser, resetUser, track, updateDetails }}>
      {children}
    </MixpanelContext.Provider>
  );
};

export function useMixpanel() {
  let context = React.useContext(MixpanelContext);
  if (context === undefined) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }
  return context;
}
